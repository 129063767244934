/**
 * Gets an error message according to its message and status.
 *
 * @example
 * getErrorMessage({ response: { statusCode: 400 } }) // bad-request
 *
 * @param {Object} error the error object containing its status code.
 * @returns {string} a string that represents the error key value.
 */
export const getErrorMessage = (error) => {
  const response = error ? error.response : null

  const status = response
    ? response.statusCode || response.status || response.code
    : 500

  if (!error) {
    return 'an-error-occurred'
  }

  if (error.message === 'Network Error') {
    return 'network-error'
  }

  if (status === 401) {
    if (response.data.message === 'Invalid email or password') {
      return 'invalid-email-pw'
    }

    return 'unauthorized'
  }

  if (status === 400) {
    return 'bad-request'
  }

  return 'an-error-occurred'
}
